import React from 'react';

import { Container, H1, Seo } from '../../components';

const NotFound: React.FC = React.memo(() => (
  <>
    <Seo title="Dog Training Book | Raising Dog" />
    <Container>
      <H1 margin="3.5rem 0" textAlign="center">
        Not found 404
      </H1>
    </Container>
  </>
));

NotFound.displayName = 'Not Found';

export default NotFound;
